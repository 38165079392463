import { render, staticRenderFns } from "./card-email-sequence.vue?vue&type=template&id=efcb5ac8&scoped=true"
import script from "./card-email-sequence.vue?vue&type=script&lang=js"
export * from "./card-email-sequence.vue?vue&type=script&lang=js"
import style0 from "./card-email-sequence.vue?vue&type=style&index=0&id=efcb5ac8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efcb5ac8",
  null
  
)

export default component.exports