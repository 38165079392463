<!-- Localized -->
<template>
    <card-template
        :item="item"
        :selected="selected"
        :compact="compact"
        class="card-email"
        :class="{
            'card-email--compact': !!compact,
        }"
    >
        <template #label-after>
            <notification v-if="compact && item.thread && item.thread.length" :count="item.thread.length" />
        </template>
        <template #content>
            <div class="property property--subject" v-html="item.subject">
                <notification v-if="item.thread && item.thread.length" :count="item.thread.length" />
            </div>
            <div class="property property--from">{{ $t('components.dashboard.email.from_s', [item.email_from]) }}</div>
            <div class="property property--to">{{ $t('components.dashboard.email.to_s', [item.email_to]) }}</div>
                <div ref="dynamicContent" class="html-content property property--description" v-html="item.html_body"></div>
        </template>
        <template #content-compact>
            <div class="property property--subject" v-html="item.subject"></div>
            <div class="property property--date" v-if="item.date">
                {{ FormatDate(item.date) }}
            </div>
        </template>
        <template #actions>
            <span v-if="item.due_days <= 0 && !(item.sequence_status === 'hold' || item.sequence_status === 'exit')"></span>
        </template>
    </card-template>
</template>

<script>
import cardTemplate from "./card-base-template.vue";
import { FormatDate } from "@/plugins/functions";
const notification = () => import("@/components/dashboard-components/checklist/components/notification.vue");

export default {
    name: "card-email",
    components: {
        cardTemplate,
        notification,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            required: false,
        },
        compact: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {};
    },
    mounted() {
        this.applyCustomAnchorColor();
    },
    methods: {
        FormatDate,
        applyCustomAnchorColor() {
            let dynamicContent = this.$refs.dynamicContent;
            let anchorTag = dynamicContent.querySelector('a');
            if (anchorTag) {
                anchorTag.style.color = 'blue';
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.html-content{
    > div {
        border-color: #ddd !important;
    }
    a {
        color: blue !important;
    }
} 
</style>
